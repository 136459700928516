<template>
  <div id="apply-item">
    <van-nav-bar :title="productItem.name" @click-left="$router.replace('/superLoan')">
      <template #left>
        <van-icon name="arrow-left" size="18" color="#2D2F31"/>
        <span style="color: #2d2f31; margin-left: 3px">返回上一页</span>
      </template>
    </van-nav-bar>
    <van-popup v-model="showLoanTime" round position="bottom">
      <van-picker
          show-toolbar
          :columns="productItem.loanTime"
          @cancel="showLoanTime = false"
          @confirm="changeLoanTime"
      />
    </van-popup>
    <div class="main">
      <header>
        <img :src="productItem.logo" alt=""/>
        <div>{{ productItem.name }}</div>
        <span>{{ productItem.label }}</span>
        <span>下款率{{ productItem.downLoanRate }}%</span>
      </header>
      <div class="tip">
        {{ productItem.description }}
      </div>
      <div class="container">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/middle-page/label.png"
            alt=""
        />
        <div>
          <div class="input">
            <label for="money">借款金额：</label>
            <input
                type="number"
                id="money"
                v-model.number="money"
                @input="setLimit"
            />
            <span>元</span>
          </div>
          <div class="input" @click="showLoanTime = true">
            <label for="">借款期限：</label>
            <div>{{ month }}<em>个月</em></div>
            <span
            ><img
                style="width: 0.4324rem; margin: 0"
                src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/middle-page/section-arrow.png"
                alt=""
            /></span>
          </div>
          <div class="loan-message">
            <div>
              <h4>{{ interest | holdTwo }}元</h4>
              <p>预估利息</p>
            </div>
            <div>
              <h4>{{ monthApply | holdTwo }}元</h4>
              <p>月还款</p>
            </div>
            <div>
              <h4>{{ (productItem.dayRate * 30) | holdTwo }}%</h4>
              <p>月利率</p>
            </div>
          </div>
        </div>
      </div>
      <div class="another-condition">
        <div class="title">申请条件</div>
        <div class="condition">
          <div
              v-show="
              productItem.applyCondition &&
              productItem.applyCondition.indexOf('1') !== -1
            "
          >
            <img
                src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/middle-page/bottom-icon2.png"
                alt=""
            />
            <p>身份证</p>
          </div>
          <div
              v-show="
              productItem.applyCondition &&
              productItem.applyCondition.indexOf('2') !== -1
            "
          >
            <img
                src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/middle-page/bottom-icon3.png"
                alt=""
            />
            <p>手机号</p>
          </div>
          <div
              v-show="
              productItem.applyCondition &&
              productItem.applyCondition.indexOf('3') !== -1
            "
          >
            <img
                src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/middle-page/bottom-icon4.png"
                alt=""
            />
            <p>银行卡</p>
          </div>
          <div
              v-show="
              productItem.applyCondition &&
              productItem.applyCondition.indexOf('4') !== -1
            "
          >
            <img
                src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/middle-page/bottom-icon5.png"
                alt=""
            />
            <p>通讯录</p>
          </div>
          <div
              v-show="
              productItem.applyCondition &&
              productItem.applyCondition.indexOf('5') !== -1
            "
          >
            <img
                src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/middle-page/bottom-icon1.png"
                alt=""
            />
            <p>20-50岁</p>
          </div>
        </div>
      </div>
      <img
          @click="tpApply"
          class="submit"
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/middle-page/submit-btn.png"
          alt=""
          v-throttle="2000"
      />
    </div>
    <div class="iframe" v-show="successUrl">
      <van-nav-bar @click-left="$router.replace('superLoan')">
        <template #left>
          <van-icon name="arrow-left" size="18" color="#2D2F31"/>
          <span style="color: #2d2f31; margin-left: 3px">返回上一页</span>
        </template>
      </van-nav-bar>
      <iframe :src="successUrl" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { to_apply_super_loan_list_product } from '_network/product'

export default {
  data() {
    return {
      productItem: {},
      minMoney: '',
      maxMoney: '',
      money: '',
      month: '',
      //选择期限的弹框
      showLoanTime: false,
      messageFinished: '',
      successUrl: ''
    }
  },
  computed: {
    //月还款
    monthApply() {
      return (this.money * this.productItem.dayRate / 100 * 30 * this.month + this.money) / this.month
    },
    //预估利息
    interest() {
      return this.money * this.productItem.dayRate / 100 * 30 * this.month
    }
  },
  methods: {
    //设置额度
    setLimit() {
      if (this.money < this.minMoney) {
        this.money = this.minMoney
        return
      }

      if (this.money > this.maxMoney) {
        this.money = this.maxMoney
      }

    },
    //选择借款期限
    changeLoanTime(time) {
      this.month = Number(time)
      this.showLoanTime = false
    },
    async tpApply() {
      try {
        const { data } = await to_apply_super_loan_list_product(this.productItem.id)
        if (data.state) {
          await this.$router.replace({
            name: 'superLoan',
            params: {
              failed: true
            }
          })
        } else {
          location.href = data.downUrl
        }
      } catch (e) {

      }
    }
  },
  filters: {
    // 保留两位小数
    holdTwo(val) {
      return Number(val).toFixed(2)
    }
  },
  created() {
    if (localStorage.getItem('productItem')) {
      this.productItem = JSON.parse(localStorage.getItem('productItem'))
    }

    const price = this.productItem.moneySection.split('-')
    this.minMoney = Number(price[0])
    this.maxMoney = Number(price[1])
    this.money = Number(this.minMoney)
    this.month = Number(this.productItem.loanTime[0])
  }
}
</script>

<style lang="less" scoped>
@import url(./index.less);
</style>
